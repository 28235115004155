import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Link, Button, Grid, Container, Paper, Breadcrumbs, useTheme } from '@material-ui/core';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Img from 'gatsby-image';

import { PageLayout } from '../layouts';
import { Box, Typography, LinkElement, Icons } from '../components';
import { truncateStringSeoFriendly } from '../helpers/truncate-string';

function MultiContentPageTemplate({ data, pageContext, path }) {
  const { images } = data;
  const theme = useTheme();
  const { body, excerpt, keywords, title, contentImages, slug } = pageContext;
  const mainImage = images.nodes.find((f) => contentImages.includes(f.childImageSharp.fluid.originalName));
  const breadcrumbPaths = path
  .split('/')
  .filter((f) => f !== '')
  .reduce((acc, curr, i) => {
    if (i === 0) {
      acc.push([curr, `/${curr}`]);
    } else {
      acc.push([curr, `${acc[i - 1][1]}/${curr}`]);
    }

    return acc;
  }, []);
  return (
    <PageLayout
      description={truncateStringSeoFriendly(excerpt)}
      title={title}
      image={mainImage ? mainImage.childImageSharp.resize : null}
      keywords={keywords}
      pathnames={[path]}
      productPage={slug.match(/produkt/) != null}
    >
      <Box bgColorClass="contrastText" marginClass="marginBottom">
        <Container maxWidth="lg">
          <Grid container direction="row" justify="space-evenly" alignItems="center">
            {breadcrumbPaths.length > 1 ? (
              <Grid item xs={12} style={{ paddingTop: '5vh' }}>
                <Breadcrumbs separator="›" aria-label="breadcrumb">
                  {breadcrumbPaths.map((b, i, arr) => (
                    <Typography key={b[1]} variant="overline" color={i === arr.length - 1 ? 'textPrimary' : 'textSecondary'}>
                      {i === arr.length - 1 ? (
                        b[0]
                      ) : (
                        <LinkElement color="inherit" to={b[1]}>
                          {b[0]}
                        </LinkElement>
                      )}
                    </Typography>
                  ))}
                </Breadcrumbs>
              </Grid>
            ) : null}

            <Grid item xs={12} style={{ paddingTop: '5vh' }}>
              <Paper elevation={4} style={{ maxWidth: 1200, maxHeight: 650 }}>
                <Img fluid={mainImage.childImageSharp.fluid} objectFit="contain" objectPosition="50% 50%" />
              </Paper>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: '5vh', paddingBottom: '5vh' }}>
              <MDXRenderer linkProps={{ path }} title={title} typographyProps={{ colorClass: 'primary' }}>
                {body}
              </MDXRenderer>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: '5vh', paddingBottom: '5vh' }}>
              <Typography variant="h6">Sie möchten mit uns Kontakt aufnehmen?</Typography>
              <Typography variant="body1">Kein Problem, klicken Sie einfach auf einen der Knöpfe.</Typography>
              <Grid container direction="column" justify="center" alignItems="stretch">
                <Grid item xs={12} md={6}>
                  <Box mt={2}>
                    <Link underline="none" href="mailto:info@stark-dynamics.com">
                      <Button
                        startIcon={<Icons iconName="mdiEmail" size={0.75} style={{ marginBottom: 2 }} />}
                        color="primary"
                        variant="contained"
                        fullWidth
                        style={{ margin: theme.spacing(1) }}
                      >
                        Mail schreiben
                      </Button>
                    </Link>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box mt={2}>
                    <Link underline="none" href="tel:+4971219942460">
                      <Button
                        startIcon={<Icons iconName="mdiPhone" size={0.75} style={{ marginBottom: 2 }} />}
                        color="primary"
                        variant="contained"
                        fullWidth
                        style={{ margin: theme.spacing(1) }}
                      >
                        Anrufen
                      </Button>
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            {breadcrumbPaths.length > 1 ? (
              <Grid item xs={12} style={{ paddingTop: '5vh' }}>
                <Breadcrumbs separator="›" aria-label="breadcrumb">
                  {breadcrumbPaths.map((b, i, arr) => (
                    <Typography key={b[1]} variant="overline" color={i === arr.length - 1 ? 'textPrimary' : 'textSecondary'}>
                      {i === arr.length - 1 ? (
                        b[0]
                      ) : (
                        <LinkElement color="inherit" to={b[1]}>
                          {b[0]}
                        </LinkElement>
                      )}
                    </Typography>
                  ))}
                </Breadcrumbs>
              </Grid>
            ) : null}
          </Grid>
        </Container>
      </Box>
    </PageLayout>
  );
}

export const query = graphql`
  query SingleContentPageTemplateQuery($contentImages: [String!]) {
    images: allFile(filter: { relativePath: { in: $contentImages } }) {
      nodes {
        id
        childImageSharp {
          fluid(maxHeight: 650, maxWidth: 1200, quality: 100) {
            originalName
            ...GatsbyImageSharpFluid
          }
          resize(width: 1200) {
            src
            height
            width
          }
        }
      }
    }
  }
`;

MultiContentPageTemplate.propTypes = {
  images: PropTypes.array,
  path: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
};

MultiContentPageTemplate.defaultProps = { images: null };

export default MultiContentPageTemplate;
